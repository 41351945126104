.App {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  margin: 0px 25px;
}

.AppWrapper{
  display: flex;
  flex-direction: column;
}

.links{
  margin-top: 50px;
  margin-bottom: 25px;
}

.text{
  margin: 0px 50px;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 600px) {
  .App {
    flex-direction: column;
    margin: 0px;
    height: auto;
  }
}
